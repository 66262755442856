
import { defineComponent, ref, reactive, onMounted, nextTick } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import { DataItem } from '@/types/app.global';
import { SearchTableExposeType, SimpleTableExposeType } from '@/types/types.config';

import { EditOutlined } from '@ant-design/icons-vue';
import SearchTable from '@/components/table/SearchTable.vue';
import ModalWidget from '@/components/widget/ModalWidget.vue';
import SimpleTable from '@/components/table/SimpleTable.vue';

export default defineComponent({
  name: 'AgentProfit',
  components: { SimpleTable, ModalWidget, SearchTable, EditOutlined },
  setup() {
    const { https, mapper, message, constants } = useInject();
    const { userMeta } = useGetters();
    const policyMap = new Map<string, any>(); // 结算价政策的集合
    const profitMap = new Map<string, any>(); // 每一种类型结算价的集合
    let agent = {} as any; // 请求参数
    let backerNo = ''; // 请求参数

    const tableRef = ref<SearchTableExposeType>();
    const tableOpts = {
      pageable: false,
      // selection: {
      //   type: 'radio'
      // },
      search: {
        criteria: {
          cols: [
            {
              field: 'targetUid',
              label: '代理',
              type: 'agent',
              evaluate: (data: DataItem) => {
                agent = data;
                return undefined;
              },
              required: true
            },
            {
              field: 'backerNo',
              label: '渠道',
              type: 'select',
              options: mapper?.device.backerNo2,
              listener: (data: string) => { backerNo = data; },
              required: true
            },
          ]
        },
        remote: {
          action: https?.market.listAgentProfit,
          handle: (data: DataItem[]) => {
            const list: DataItem[] = [];
            let id = 0;
            let rateRange = '';
            let extRange = '';
            let policy;
            data.forEach(x => {
              for (const [k, v] of Object.entries(x)) {
                const profit = profitMap.get(k + '-' + x.backerNo);
                if (profit && profit.status === true) {
                  policy = policyMap.get(profit.bizType);
                  rateRange = '(' + policy.minRate + '~' + policy.maxRate + ')';
                  extRange = '(' + policy.minExt + '~' + policy.maxExt + ')';
                  list.push({ id, uid: x.uid, backerNo: profit.backerNo, bizType: profit.bizType, bizDesc: profit.bizDesc, toRate: v, toExt: x['ext' + k], rateRange, extRange });
                  id += 1;
                }
              }
            });
            return list;
          }
        }
      },
      cols: [
        {
          field: 'uid',
          label: '代理ID'
        },
        {
          field: 'backerNo',
          label: '渠道',
          options: mapper?.device.backerNo
        },
        {
          field: 'bizType',
          label: '业务类型'
        },
        {
          field: 'bizDesc',
          label: '业务描述'
        },
        {
          field: 'toRate',
          label: '费率'
        },
        {
          field: 'toExt',
          label: '额外手续费'
        }
      ]
    };

    // 单条修改逻辑，先注掉
    // const modalOpts = reactive({
    //   title: '结算价修改申请',
    //   visible: false,
    //   action: https?.market.updateAgentProfit,
    //   content: {
    //     entity: {} as DataItem,
    //     tips: {
    //       msg: '修改申请不会立即生效，需下级代理同意。额外手续费暂只支持【刷卡T0贷记卡】修改'
    //     },
    //     cols: [
    //       {
    //         field: 'uid',
    //         label: '代理ID',
    //         disabled: true
    //       },
    //       {
    //         field: 'backerNo',
    //         label: '渠道',
    //         type: 'select',
    //         options: mapper?.device.backerNo,
    //         disabled: true
    //       },
    //       {
    //         field: 'bizType',
    //         label: '业务类型',
    //         disabled: true
    //       },
    //       {
    //         field: 'bizDesc',
    //         label: '业务描述',
    //         disabled: true
    //       },
    //       {
    //         field: 'toRate',
    //         label: '费率',
    //         type: 'number',
    //         scale: 4,
    //         required: true
    //       },
    //       {
    //         field: 'toExt',
    //         label: '额外手续费',
    //         type: 'number',
    //         max: 3,
    //         scale: 2,
    //         required: true
    //       }
    //     ],
    //     evaluate: (data: DataItem) => {
    //       const list = [{ bizType: data.bizType, bizDesc: data.bizDesc, toRate: data.toRate, toExt: data.toExt }];
    //       const body = { operator: userMeta.uid, oprAlias: userMeta.alias, oprPhone: userMeta.phone, oprName: userMeta.name,
    //         receiver: data.uid, backerNo: data.backerNo, detailList: list };
    //       return { modifyBody: JSON.stringify(body) };
    //     }
    //   }
    // });
    //
    // const showModal = () => {
    //   const row = tableRef.value?.expose.getSelectedData()[0];
    //   if (!row) {
    //     message?.warn('请选择一条数据！');
    //     return;
    //   }
    //
    //   if (agent.type !== constants?.enums.userType.agent) {
    //     message?.warn('非直签代理，不能修改结算价！');
    //     return;
    //   }
    //
    //   modalOpts.content.entity = row;
    //   modalOpts.visible = true;
    // };

    const doModify = () => {
      const data = detailRef.value?.expose.getData();
      const list = [] as any[];
      let msg = '';
      let policy;
      data?.forEach(x => {
        policy = policyMap.get(x.bizType);

        // 检查是否超出了范围
        if (x.toRate > policy.maxRate || x.toRate < policy.minRate) {
          msg = x.bizType + '的费率[' + x.toRate + ']超出了合理区间(' + policy.minRate + '~' + policy.maxRate + ')';
        } else if (x.toExt > policy.maxExt || x.toExt < policy.minExt) {
          msg = x.bizType + '的额外手续费[' + x.toExt + ']超出了合理区间(' + policy.minExt + '~' + policy.maxExt + ')';
        }

        // 对表格的数据进行转换，只需要四个字段
        list.push({ bizType: x.bizType, bizDesc: x.bizDesc, toRate: x.toRate, toExt: x.toExt });
      });

      if (msg) {
        message?.warn(msg);
        return;
      }

      const body = { operator: userMeta.uid, oprAlias: userMeta.alias, oprPhone: userMeta.phone, oprName: userMeta.name,
        receiver: agent.uid, backerNo, detailList: list };

      https?.market.updateAgentProfit({ modifyBody: JSON.stringify(body) }).then(res => {
        modifyOpts.visible = false;
        if (res.respType === 'OK') {
          message?.success('代理结算价修改申请已提交');
        } else {
          message?.error('代理结算价修改申请提交失败: ' + res.respMesg);
        }
      });
    };

    const detailRef = ref<SimpleTableExposeType>();
    const modifyOpts = reactive({
      title: '结算价修改申请',
      visible: false,
      width: '80%',
      action: doModify,
    });

    const detailOpts = {
      cols: [
        {
          field: 'backerNo',
          label: '渠道',
          options: mapper?.device.backerNo
        },
        {
          field: 'bizType',
          label: '业务类型'
        },
        {
          field: 'bizDesc',
          label: '业务描述'
        },
        {
          field: 'toRate',
          label: '费率',
          editable: 'number',
          scale: 4,
          extra: {
            field: 'rateRange',
            style: 'color: red; margin-left: 5px'
          }
        },
        {
          field: 'toExt',
          label: '额外手续费',
          editable: 'number',
          max: 3,
          scale: 2,
          extra: {
            field: 'extRange',
            style: 'color: red; margin-left: 5px'
          }
        },
      ]
    };

    const showModify = () => {
      const list = tableRef.value?.expose.getData() || [];
      if (list.length === 0) {
        message?.warn('代理结算价记录为空！');
        return;
      }

      if (agent.type !== constants?.enums.userType.agent) {
        message?.warn('非直签代理，不能修改结算价！');
        return;
      }

      modifyOpts.visible = true;
      nextTick(() => {
        detailRef.value?.expose.setData(list);
      });
    };

    onMounted(() => {
      https?.market.listProfitPolicy({ page: 1, size: 100, status: '1' }).then(res => {
        if (res.respType === 'OK') {
          res.respData.records.forEach((x: any) => {
            profitMap.set(x.bizType + '-' + x.backerNo, x);
            policyMap.set(x.bizType, x);
          });
        } else {
          message?.error('结算价政策查询失败');
        }
      });
    });
    return { tableRef, tableOpts, detailRef, detailOpts, modifyOpts, showModify };
  }
});
