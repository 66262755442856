import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_search_table = _resolveComponent("search-table")!
  const _component_simple_table = _resolveComponent("simple-table")!
  const _component_modal_widget = _resolveComponent("modal-widget")!
  const _directive_perm = _resolveDirective("perm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_search_table, {
      ref: "tableRef",
      opts: _ctx.tableOpts
    }, {
      button: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_a_button, { onClick: _ctx.showModify }, {
              icon: _withCtx(() => [
                _createVNode(_component_edit_outlined)
              ]),
              default: _withCtx(() => [
                _createTextVNode("修改申请")
              ]),
              _: 1
            }, 8, ["onClick"])), [
              [_directive_perm, void 0, "update"]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["opts"]),
    _createVNode(_component_modal_widget, { opts: _ctx.modifyOpts }, {
      content: _withCtx(() => [
        _createVNode(_component_simple_table, {
          ref: "detailRef",
          opts: _ctx.detailOpts
        }, null, 8, ["opts"])
      ]),
      _: 1
    }, 8, ["opts"])
  ]))
}